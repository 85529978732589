























































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import config from '@/config';
import axios from 'axios';
import * as notifications from '@/notifications';

@Component({})
export default class MemberMeNotifications extends Vue {
  @Prop() public id!: number;

  public $store: any;
  public $q: any;
  public $route: any;
  public loading: boolean = true;
  public prefs: any = {};
  public groups: any = [];

  private connectivityOptions: any = [
    {
      label: '1h',
      value: 1,
    },
    {
      label: '6h',
      value: 6,
    },
    {
      label: '12h',
      value: 12,
    },
    {
      label: '24h',
      value: 24,
    },
    {
      label: '72h',
      value: 72,
    },
  ];

  public async created() {
    this.refresh();
    this.$store.dispatch('checkWebPush');
  }

  @Watch('prefs', { deep: true })
  private editPreferences(value: any) {
    if (this.loading) {
      return;
    }
    this.$store.dispatch('editMemberMePreferences', {
      payload: this.prefs,
    });
  }

  @Watch('$route')
  private refresh() {
    this.loading = true;

    const promise1 = axios.get(`${config.api_url}/notifications/groups`)
    .then((response: any) => {
      this.groups = response.data.groups;
    }).catch((error: any) => {
      this.globalError(error);
    });

    const promise2 = this.$store.dispatch('loadMemberMePreferences')
    .then((response: any) => {
      this.prefs = response;
    }).catch((error: any) => {
      this.globalError(error);
    });

    Promise.all([promise1, promise2])
    .then(() => {
      this.loading = false;
    });
  }
}
